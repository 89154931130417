





























































































import { createPagination } from '@/constant/constant'
import { i18n } from '@/i18n/i18n'
import { MessageController } from '@/services/request.service'
import { Component, Vue, Watch, Inject } from 'vue-property-decorator'
import SvgIcon from '@/components/SvgIcon.vue'
import moment from 'moment'
import { getSchoolInfo } from '@/utils/utils'

const operations = {
  inbox: function(condition) {
    return MessageController.getToList(condition)
  },
  outbox: function(condition) {
    return MessageController.getFromList(condition)
  },
  outboxDel: function(id) {
    return MessageController.deleteFrom(id)
  },
  inboxDel: function(id) {
    return MessageController.deleteTo(id)
  },
}

const analyticsMsg = {
  inbox: '进入收件箱',
  outbox: '查看发件箱',
}

@Component({
  components: {
    SvgIcon,
  },
})
export default class Message extends Vue {
  @Inject() public readonly baidu!: any

  private boxType: any = 'inbox'
  private data: any = []
  private finished: any = false
  private inboxType: any = 'normal'
  private loading: any = false
  private pagination: any = createPagination({
    current: 0,
    showTotal: total => i18n.t('common.pageTotal', { total }),
  })
  private refreshing: any = false

  private get schoolId(): number {
    return (getSchoolInfo() || {}).schoolId
  }

  private get school(): any {
    const school = getSchoolInfo()
    if (school && Object.keys(school).length) {
      return school
    } else {
      return {
        name: '',
        enName: '',
      }
    }
  }

  private get isChildRoute(): boolean {
    return this.$route.name !== 'message'
  }

  private get studentId(): any {
    return this.$store.state.currentStudent?.studentId
  }

  private get unreadData(): boolean {
    return this.$store.state.unreadData
  }

  private get unreadSysData(): boolean {
    return this.$store.state.unreadSysData
  }

  private get cnName(): any {
    return this.$store.state.currentStudent.name
  }

  private get enName(): any {
    return this.$store.state.currentStudent.enName || this.$store.state.currentStudent.firstName
  }

  private get lastName(): any {
    return this.$store.state.currentStudent.lastName
  }

  private get studentName(): any {
    return this.$store.state.currentStudent.fullName
  }

  private get studentStatus(): any {
    return this.$store.state.currentStudent?.status
  }

  private created(): any {
    this.refresh()
  }

  private deleteMessage(item): void {
    this.$dialog
      .confirm({
        message: this.$tc('common.deleteConfirm'),
        confirmButtonColor: '#26b889',
      })
      .then(action => {
        const messageId = this.boxType === 'inbox' ? item.messageId : item.messageMasterId
        operations[this.boxType + 'Del'](messageId).then(res => {
          this.refresh()
          this.$message.success(this.$tc('common.deleteSuccess'))
        })
      })
      .catch(() => {})
  }

  private fetchData(cb): void {
    this.loading = true
    this.baidu.event(this.school.name, analyticsMsg[this.boxType], '手机')

    let { current, pageSize } = this.pagination
    const condition = {
      pageCurrent: current,
      pageSize: pageSize,
      studentId: this.studentId,
      queryType:
        this.boxType === 'inbox'
          ? this.inboxType === 'normal'
            ? 'Normal'
            : 'SystemMessage'
          : undefined,
    }
    operations[this.boxType](condition)
      .then(res => cb(res))
      .catch(err => console.error(err))
      .finally(() => (this.loading = false))
  }

  private handleLoad(): void {
    // if(this.refreshing){
    //   this.refreshing = false
    //   return
    // }
    if (this.finished) {
      this.loading = false
      return
    }
    // return
    const preData = this.data
    this.loading = true
    const type = this.boxType
    this.pagination.current++
    this.fetchData(res => {
      if (type !== this.boxType) return
      this.pagination.total = res.data.totalItem
      const mes4Type = {
        1635: () =>
          `【${this.cnName}】的家长您好：\
          Dear Parents of 【${this.enName} ${this.lastName}】\
          您收到这个信息是因为您的孩子的出勤标记从缺勤（无理由）被更新了：\
          You are receiving this message because your child‘s attendance code has been updated from absent (unexcused) in the following class(es):`,
        1636: date =>
          `【${this.cnName}】的家长您好：\
            Dear Parents of 【${this.enName} ${this.lastName}】,\
            您收到这个信息是因为在今天的{{type === 'morning' ? '早上十点前': '下午五点前'}}，在以下的班级里您的孩子被老师标记了缺勤（无理由）：\
            You are receiving this message because as of ${
              type === 'morning' ? '10:00AM' : '5:00PM'
            } on 【${moment(date).format('YYYY/MM/DD')}】, \
            your child was marked absent (unexcused) in the following`,
        1637: date =>
          `您好，老师为【${this.studentName}】，新增了一条日记，详情如下：\
          Hello, teacher has added a new daybook for [${this.studentName}]. Details are as follows:`,
      }
      let data = res.data.items.map(item => ({
        ...item,
        content: mes4Type[item.type]?.(item.sendTime) || item.content,
      }))
      this.data = preData.concat(data)
      this.loading = false
      this.refreshing = false
      if (this.data.length >= res.data.totalItem) {
        this.finished = true
      }
    })
  }

  private onRefresh() {
    this.data = []
    this.pagination.current = 0
    this.finished = false
    this.refreshing = true
    this.handleLoad()
  }

  private refresh() {
    this.data = []
    this.pagination.current = 0
    this.finished = false
    this.handleLoad()
  }

  private onTypeChange(): void {
    this.$nextTick(() => {
      this.refresh()
    })
  }

  private onInTypeChange(params): void {
    this.$nextTick(() => {
      this.refresh()
    })
  }

  private viewDetail(item, readFlag): void {
    const isIn = this.boxType === 'inbox'
    if (!readFlag && isIn) {
      MessageController.setRead(item.messageId)
    }
    this.$router.push({
      name: 'messageDetail',
      params: { messageId: isIn ? item.messageId : item.messageMasterId },
      query: { detailType: this.boxType, mesType: this.inboxType },
    })
  }

  private toSend(): void {
    this.baidu.event(this.school.name, '发消息', '手机')

    this.$router.push({ name: 'sendMessage', query: { type: 'new' } })
  }

  @Watch('studentId', { immediate: true })
  private onStudentChange(val): void {
    this.refresh()
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(to): void {
    if (to.name === 'message') this.refresh()
  }
}
