














































































































































































import { Component, Vue } from 'vue-property-decorator'
import cloneDeep from 'lodash/cloneDeep'
import { MessageController, TeacherController } from '@/services/request.service'
import Transfer from '@/components/Transfer.vue'
import GroupTransfer from '@/components/GroupTransfer.vue'
import Upload from '@/components/Upload.vue'
import moment from 'moment'
import { SimditorVue } from '@/components/simditor/Simditor'

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate', // for vue-router 2.2+
])

const operations = {
  outbox: function(id) {
    return MessageController.getFromDetail(id)
  },
  inbox: function(id) {
    return MessageController.getToDetail(id)
  },
}

@Component({
  components: {
    Transfer,
    GroupTransfer,
    Upload,
    SimditorVue,
  },
})
export default class Send extends Vue {
  private canLeave: any = false
  private dataLoading: any = false
  private formItemLayout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 10 },
  }
  private history: Array<any> = []
  private messageForm: any = {
    attachments: [],
  }
  private moment = moment
  private originParents: any = []
  private sending: any = false
  private spinning: boolean = false
  private students: any = []
  private teachers: any = []
  private transferType = ''
  private transferCandidate: Array<any> = []
  private transferSelected: Array<any> = []
  private transferVis: any = false
  private uploading: any = false

  private get recipientError(): any {
    return this.messageForm.teachers && !(this.messageForm.teachers || []).length
  }
  private set recipientError(val) {
    this.$set(this.messageForm, 'teachers', [])
  }

  private get studentId(): any {
    return this.$store.state.currentStudent?.studentId
      ? parseInt(this.$store.state.currentStudent.studentId, 10)
      : undefined
  }

  private get type(): any {
    return this.$route.query.type
  }

  private add(): void {
    this.transferVis = true
    this.transferCandidate = this.teachers
    this.transferSelected = this.messageForm.teachers
  }

  private beforeRouteLeave(to, from, next): void {
    if (!this.$store.state.forceBack) {
      const message = {
        title: this.messageForm.title,
        content: this.messageForm.content,
        attachments: this.messageForm.attachments?.length,
        selectedTeachers: this.messageForm.teachers?.length,
      }
      if (Object.values(message).some(item => item)) {
        if (!this.canLeave) {
          this.$confirm({
            title: this.$t('common.unsaveConfirm') as string,
            onOk: () => {
              next()
            },
            onCancel: () => {
              next(false)
            },
          })
        } else {
          next()
        }
      } else {
        next()
      }
    } else {
      next()
    }
  }

  private cancel(): void {
    this.$router.back()
  }

  private created(): void {
    this.getDropDownInfo()
    this.getDetail()
  }

  private getDropDownInfo(): void {
    this.dataLoading = true
    TeacherController.getRelatedTeachers(this.studentId)
      .then(res => {
        this.teachers = res.data.map(item => {
          return {
            id: item.teacherId,
            title:
              (item.displayName || (item.enName + ' ' + item.name).trim()) +
              (item.relation ? `(${item.relation})` : ''),
            selected: false,
          }
        })
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.dataLoading = false
      })
  }

  private getDetail(): void {
    if (!this.$route.query.messageId) return
    const messageId = parseInt(this.$route.query.messageId as any, 10)
    operations[this.type](messageId)
      .then(res => {
        this.history = res.data.map(item => ({
          ...item,
          toMembers: (item.toTeachers || []).concat(item.toStudents || [], item.toParents || []),
          fold: true,
        }))
        let { fromMember } = this.history[0]
        if (this.$route.name === 'sendMessage') {
          this.$set(this.messageForm, 'content', res.data[0].content)
          ;(this.$refs.mSim as any).changeValue(res.data[0].content)
        } else {
          this.messageForm.teachers = [
            {
              title: fromMember.displayName.length
                ? fromMember.displayName
                : `${fromMember.enName} ${fromMember.name}`,
              id: fromMember.memberId,
              isRecall: true,
            },
          ]
        }
        this.$set(
          this.messageForm,
          'title',
          (this.$route.name === 'sendMessage' ? 'Fw: ' : 'Re: ') + res.data[0].title
        )
        if (res.data[0].resources.length) {
          this.$set(
            this.messageForm,
            'attachments',
            res.data[0].resources.map(item => ({
              name: item.resourceName,
              percent: 100,
              status: 'done',
              uid: item.resourceId,
              url: item.resourceUrl,
            }))
          )
        }
      })
      .catch(err => {
        console.error(err)
      })
  }

  private getSelectedData(data): void {
    this.$set(this.messageForm, 'teachers', cloneDeep(data))
  }

  private removeItem(id): void {
    const index = this.messageForm.teachers?.findIndex(teacher => teacher.id === id)
    this.messageForm.teachers?.splice(index, 1)
    this.$forceUpdate()
  }

  private send(): void {
    const form = this.$refs['messageForm'] as any
    form.validate(valid => {
      let { teachers } = this.messageForm
      let otherValid = true
      if (!(teachers || []).length) {
        this.recipientError = true
        otherValid = false
      }
      if (!valid || !otherValid) return false
      this.sending = true
      const message = {
        ...this.messageForm,
        studentId: this.studentId,
        forwardMessageId:
          this.type === 'inbox' && this.$route.name === 'sendMessage'
            ? parseInt(this.$route.query.messageId as any, 10)
            : undefined,
        forwardMasterId:
          this.type === 'outbox' && this.$route.name === 'sendMessage'
            ? parseInt(this.$route.query.messageMasterId as any, 10)
            : undefined,
        messageParentId:
          this.$route.name === 'replyMessage'
            ? parseInt(this.$route.query.messageMasterId as any, 10)
            : undefined,
        resourceIds: this.messageForm.attachments.map(attachment => attachment.uid),
        toTeacherIds: this.messageForm.teachers?.map(teacher => teacher.id),
      }
      MessageController.sendMessage(message)
        .then(res => {
          this.$message.success(this.$tc('common.sendSuccess'))
          this.canLeave = true
          this.$route.meta.refresh = true
          this.$router.replace({ name: 'outbox' })
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.sending = false
        })
    })
  }
}
