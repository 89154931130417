










































import { Vue, Component, Prop, Model, Emit, Watch } from 'vue-property-decorator'
import { fileUploader } from '@/services/qiniu.service'
import { guid } from '@/utils/utils'
import cloneDeep from 'lodash/cloneDeep'

@Component
export default class Upload extends Vue {
  @Model('change', { type: Array }) private value!: any
  @Prop({ default: () => ({ maxNum: 5, maxSize: 500, unit: 'M' }) }) private readonly option!: any

  @Emit('change')
  private listChange(): any {
    return this.fileList
  }

  @Watch('value', { immediate: true, deep: true })
  private onAttachChange(val) {
    if (!this.loadingCount) {
      this.fileList = cloneDeep(val) || []
    }
  }

  private currentFileName: any = ''
  private fileList: any = []
  private accept: any = [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/pdf',
    'image/jpg',
    'image/jpeg',
    'image/png',
    'video/quicktime',
    'audio/x-mpeg',
  ]
  private loadingCount: any = 0

  private get rOption(): any {
    const { maxNum = 5, maxSize = 500, unit = 'M' } = this.option
    return { maxNum, maxSize, unit }
  }

  private get progress(): any {
    return this.$store.state.fileUploadProgress
  }

  @Watch('progress', { deep: true, immediate: true })
  private onProgressChange(val): any {
    Object.keys(val).forEach(key => {
      if (val[key] <= 100) {
        let attachment = this.fileList.find(attachment => attachment.url.includes(key))
        if (attachment && attachment.status === 'uploading') {
          attachment.percent = val[key]
        }
      }
    })
  }

  @Watch('loadingCount', { immediate: true })
  private onLoadingChange(val): any {
    this.$emit('loadingChange', val !== 0)
  }

  private customRequest({ file }): void {
    if (this.fileList.length >= this.rOption.maxNum) return
    const suffixArray = ['.exe', '.bat', '.com'] as any
    const index = file.name.lastIndexOf('.')
    const suffix = file.name.slice(index).toLowerCase()
    if (suffixArray.includes(suffix)) {
      const tips = suffixArray.join(',').replace(/\./g, '')
      this.$message.error(
        this.$t('tips.rejectFileTips', { tips: suffixArray.join(', ') }) as string
      )
      return
    }
    if (file.size > this.rOption.maxSize * 1024 * 1024) {
      this.$message.error(
        this.$t('tips.attachmentTip', {
          size: `${this.rOption.maxSize}${this.rOption.unit}`,
        }) as string
      )
      return
    }

    // 构造文件名
    const fileName = 'file_' + new Date().getTime() + suffix
    this.currentFileName = fileName

    let attachment = {
      uid: guid() as any,
      url: process.env.VUE_APP_FILE_URL + fileName,
      name: file.name,
      status: 'uploading',
      percent: 0,
    }
    this.fileList.push(attachment)

    this.loadingCount++
    fileUploader(file, fileName, 'file')
      .then(res => {
        attachment.uid = res.resourceId
        attachment.status = 'done'
        this.listChange()
      })
      .catch(err => {
        console.error(err)
        attachment.status = 'error'
        this.$set(attachment, 'error', { statusText: this.$t('tips.uploadFailed') })
      })
      .finally(() => {
        this.loadingCount--
      })
  }

  private removeFile(file): void {
    let index = this.fileList.indexOf(file)
    this.fileList.splice(index, 1)
    this.listChange()
  }
}
