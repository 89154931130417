


































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class DiaryMes extends Vue {
  @Prop({}) private type!: any
  @Prop({}) private date!: any
  @Prop({}) private data!: any

  private get studentName(): any {
    return this.$store.state.currentStudent.fullName
  }
  private get locale(): any {
    return this.$store.state.locale
  }
}
