

















































































































































import { Vue, Component, Prop, Watch, Inject } from 'vue-property-decorator'
import AddPerson from '@/components/MobileComponents/AddPerson.vue'
import { MessageController, TeacherController } from '@/services/request.service'
import { Toast } from 'vant'
import { guid, getSchoolInfo } from '@/utils/utils'
import { fileUploader } from '@/services/qiniu.service'
@Component({
  components: {
    AddPerson,
  },
})
export default class send extends Vue {
  @Inject() public readonly baidu!: any

  private showAdd: boolean = false
  private recipients: any = []
  private dataLoading: any = false
  private selectedTeachers: any = []
  private fileList: any = []
  private history: any = []
  private message: any = {
    title: '',
    content: '',
  }
  private saved: any = false
  private loadingCount: any = 0
  private sending: any = false

  private get studentId(): any {
    return this.$store.state.currentStudent?.studentId
      ? parseInt(this.$store.state.currentStudent.studentId, 10)
      : undefined
  }

  private get school(): any {
    const school = getSchoolInfo()
    if (school && Object.keys(school).length) {
      return school
    } else {
      return {
        name: '',
        enName: '',
      }
    }
  }

  private get sendType(): any {
    return this.$route.query.type || 'new'
  }

  private get username(): any {
    const student = this.$store.state.currentStudent
    return `${student.fullName} ${
      student.relation ? this.$t(`relation.${student.relation}`) : ''
    }`.trim()
  }

  private asyncBeforeRead(file): any {
    return new Promise((resolve, reject) => {
      const multipul = Array.isArray(file)
      if (multipul) {
        file.forEach(sFile => {
          this.handleFile(sFile, resolve, reject)
        })
      } else {
        this.handleFile(file, resolve, reject)
      }
    })
  }

  private handleFile(file, resolve, reject): void {
    if (this.fileList.length >= 5) {
      reject()
      return
    }
    const suffixArray = ['.exe', '.bat', '.com'] as any
    const index = file.name.lastIndexOf('.')
    const suffix = file.name.slice(index).toLowerCase()
    if (suffixArray.includes(suffix)) {
      const tips = suffixArray.join(',').replace(/\./g, '')
      Toast(this.$t('tips.rejectFileTips', { tips: suffixArray.join(', ') }) as string)
      reject()
      return
    }
    if (file.size > 500 * 1024 * 1024) {
      Toast(this.$t('tips.attachmentTip', { size: '500M' }) as string)
      reject()
      return
    }

    // 构造文件名
    const fileName = 'file_' + new Date().getTime() + suffix
    let attachment = {
      uid: guid() as any,
      url: process.env.VUE_APP_FILE_URL + fileName,
      name: file.name,
      status: 'uploading',
      percent: 0,
    }
    this.fileList.push(attachment)

    this.loadingCount++
    fileUploader(file, fileName, 'file')
      .then(res => {
        attachment.uid = res.resourceId
        attachment.status = 'done'
        ;(this.$refs.sendForm as any)?.validate('content')
        resolve(false)
      })
      .catch(err => {
        console.error(err)
        reject
      })
      .finally(() => {
        this.loadingCount--
      })
  }

  private addRecipient(): void {
    this.showAdd = true && !this.dataLoading
  }

  private back(): any {
    this.$router.go(-1)
  }

  private created(): any {
    this.getDropDownInfo()
  }

  private cValidator(val): any {
    return !!val.length || !!this.fileList.length
  }

  private getDropDownInfo(): void {
    this.dataLoading = true
    TeacherController.getRelatedTeachers(this.studentId)
      .then(res => {
        this.recipients = res.data.map(item => {
          return {
            id: item.teacherId,
            name:
              (item.displayName || (item.enName + ' ' + item.name).trim()) +
              (item.relation ? `(${item.relation})` : ''),
          }
        })
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.dataLoading = false
      })
  }

  private getDetail(): void {
    const messageId = Number(this.$route.query.messageId)
    MessageController.getToDetail(messageId)
      .then(res => {
        this.history = res.data.map(item => ({
          ...item,
          toMembers: item.toTeachers || [],
          toTeacherNames: item.toTeachers
            ?.map(item =>
              item.displayName.length ? item.displayName : `${item.name} ${item.enName}`.trim()
            )
            .join('、'),
        }))
        this.message.title = 'Re: ' + res.data[0].title
        this.selectedTeachers = [
          {
            name: res.data[0].fromMember.displayName.length
              ? res.data[0].fromMember.displayName
              : (res.data[0].fromMember.enName + ' ' + res.data[0].fromMember.name).trim(),
            id: res.data[0].fromMember.memberId,
          },
        ]
      })
      .catch(err => {
        console.error(err)
      })
  }

  private onSelectedConfirm(items): void {
    this.selectedTeachers = items
  }

  private removeFile(uid): void {
    this.fileList = this.fileList.filter(item => item.uid !== uid)
    ;(this.$refs.sendForm as any)?.validate('content')
  }

  private rValidator(val): any {
    return !!val.length
  }

  private send(values): any {
    this.sending = true
    const message = {
      ...this.message,
      studentId: this.studentId,
      resourceIds: this.fileList.map(attachment => attachment.uid),
      toTeacherIds: this.selectedTeachers.map(teacher => teacher.id),
      messageParentId: this.sendType === 'new' ? undefined : Number(this.$route.query.fromId),
    }
    MessageController.sendMessage(message)
      .then(res => {
        this.$notify({ type: 'success', message: this.$tc('common.sendSuccess') })
        this.saved = true
        this.$route.meta.refresh = true
        this.$router.replace({ name: 'message' })

        this.baidu.event(this.school.name, '成功发消息', '手机')
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.sending = false
      })
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(to): void {
    this.$route.meta.routerName = to.query.type === 'reply' ? 'replyMessage' : 'sendMessage'
    if (to.query.type === 'reply') {
      this.getDetail()
    }
  }
}
