

















































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { getUserAuth } from '@/utils/utils'
import { DropDownController, MessageController } from '@/services/request.service'
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import moment from 'moment'
import { i18n } from '@/i18n/i18n'
import filterGroup from '@/components/filterGroup'
import FlexTooltip from '@/components/FlexTooltip.vue'
import { toPage, getSchoolInfo } from '@/utils/utils'
import { createPagination } from '@/constant/constant'
import SvgIcon from '@/components/SvgIcon.vue'

@Component({
  components: {
    filterGroup,
    FlexTooltip,
    SvgIcon,
  },
})
export default class Inbox extends Vue {
  private recipients: Array<any> = []
  private senders: Array<any> = []
  private filter: any = {
    title: null,
    receivedTime: [],
  }
  private pagination: any = createPagination({
    showTotal: total => i18n.t('common.pageTotal', { total }),
  })
  private data: Array<any> = []
  private loading: boolean = false
  private reqId: any = 0

  private moment = moment

  private get schoolId(): number {
    return (getSchoolInfo() || {}).schoolId
  }

  private get studentStatus(): any {
    return this.$store.state.currentStudent?.status
  }

  private get studentId(): any {
    return this.$store.state.currentStudent?.studentId
      ? parseInt(this.$store.state.currentStudent.studentId, 10)
      : undefined
  }

  private get cnName(): any {
    return this.$store.state.currentStudent.name
  }

  private get enName(): any {
    return this.$store.state.currentStudent.enName || this.$store.state.currentStudent.firstName
  }

  private get lastName(): any {
    return this.$store.state.currentStudent.lastName
  }

  private get isChildRoute(): boolean {
    return this.$route.name !== 'inbox'
  }

  private get locale(): string {
    return this.$store.state.locale
  }

  private get columns(): Array<any> {
    return [
      {
        key: 'title',
        title: this.$t('message.title'),
        scopedSlots: { customRender: 'name' },
        align: 'left',
        ellipsis: true,
      },
      {
        key: 'content',
        title: this.$t('message.content'),
        ellipsis: true,
        scopedSlots: { customRender: 'content' },
        align: 'left',
      },
      {
        key: 'sender',
        title: this.$t('message.sender'),
        align: 'left',
        scopedSlots: { customRender: 'sender' },
      },
      {
        key: 'receivedTime',
        title: this.$t('message.receivedTime'),
        align: 'left',
        scopedSlots: { customRender: 'receivedTime' },
      },
      {
        key: 'operations',
        width: 160,
        title: this.$t('common.operations'),
        scopedSlots: { customRender: 'operations' },
      },
    ]
  }

  private get viewList(): Array<any> {
    return [
      {
        key: 'normal',
        label: this.$t('message.normalMes'),
        dot: this.unreadStatus,
      },
      {
        key: 'system',
        label: this.$t('router.systemMessage'),
        dot: this.unreadSysStatus,
      },
    ]
  }

  private get type(): any {
    return this.$route.params.mesType
  }

  private get unreadSysStatus(): any {
    let flag = false
    const unreadSysData = this.$store.state.unreadSysData
    for (let key in unreadSysData) {
      if (unreadSysData[key]) {
        flag = true
      }
    }
    return flag
  }

  private get unreadStatus(): any {
    let flag = false
    const unreadData = this.$store.state.unreadData
    for (let key in this.$store.state.unreadData) {
      if (unreadData[key]) {
        flag = true
      }
    }
    return flag
  }

  private get studentName(): any {
    return this.$store.state.currentStudent.fullName
  }

  @Watch('studentId', { immediate: true })
  private onStudentChange(val): void {
    if (val) {
      this.getData()
    }
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(to, from): void {
    if (to.name === 'inbox' && !to.params.mesType) {
      this.$router.replace({ params: { mesType: 'normal' } })
      return
    }
    if (from && from.meta.refresh === true) {
      this.refresh()
    }
    if (from?.name === to.name && from.params.mesType !== to?.params.mesType) {
      this.getData()
    }
  }

  private changeType(val): void {
    this.loading = false
    this.$router.push({ name: 'inbox', params: { mesType: val.target.value } }).catch(err => {})
  }

  private deleteRecord(record): void {
    MessageController.deleteTo(record.messageId).then(res => {
      this.$message.success(this.$tc('common.deleteSuccess'))
      this.refresh()
    })
  }

  private reset(): void {
    this.filter = {
      title: null,
      receivedTime: [],
    }
    this.$set(this.pagination, 'current', 1)
    this.refresh()
  }

  private getData(page = { current: 1, pageSize: this.pagination.defaultPageSize }): void {
    const { title, receivedTime } = this.filter
    this.loading = true
    this.data = []
    const type = this.type
    const reqId = ++this.reqId
    let condition = {
      pageCurrent: page.current,
      pageSize: page.pageSize,
      studentId: this.studentId,
      title: title ? title.trim() : undefined,
      startTime: receivedTime[0] ? moment(receivedTime[0]).valueOf() : undefined,
      endTime: receivedTime[1] ? moment(receivedTime[1]).valueOf() : undefined,
      queryType: this.type === 'normal' ? 'Normal' : 'SystemMessage',
    }
    this.getMessageIds(condition, reqId)
    MessageController.getToList(condition)
      .then(res => {
        if (type !== this.type || reqId !== this.reqId) return
        const mes4Type = {
          1635: () =>
            `【${this.cnName}】的家长您好：\
            Dear Parents of 【${this.enName} ${this.lastName}】\
            您收到这个信息是因为您的孩子的出勤标记从缺勤（无理由）被更新了：\
            You are receiving this message because your child‘s attendance code has been updated from absent (unexcused) in the following class(es):`,
          1636: date =>
            `【${this.cnName}】的家长您好：\
              Dear Parents of 【${this.enName} ${this.lastName}】,\
              您收到这个信息是因为在今天的{{type === 'morning' ? '早上十点前': '下午五点前'}}，在以下的班级里您的孩子被老师标记了缺勤（无理由）：\
              You are receiving this message because as of ${
                type === 'morning' ? '10:00AM' : '5:00PM'
              } on 【${moment(date).format('YYYY/MM/DD')}】, \
              your child was marked absent (unexcused) in the following`,
          1637: date =>
            `您好，老师为【${this.studentName}】，新增了一条日记，详情如下：\
            Hello, teacher has added a new daybook for [${this.studentName}]. Details are as follows:`,
        }
        this.data = res.data.items.map(item => ({
          ...item,
          content: mes4Type[item.type]?.(item.sendTime) || item.content,
        }))
        this.pagination.total = res.data.totalItem
        this.pagination.current = page.current
        toPage(page, this.data, page => {
          this.pagination.current = page
          this.refresh()
        })
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => (this.loading = false))
  }

  private getMessageIds(condition, reqId): void {
    const type = this.type
    MessageController.getToList({
      ...condition,
      pageSize: 0,
      pageCurrent: 1,
    }).then(res => {
      if (type !== this.type || reqId !== this.reqId) return
      this.$store.commit(
        'setAllMessageIds',
        res.data.items.map(item => item.messageId)
      )
    })
  }

  private inputChange = debounce(() => {
    this.getData()
  }, 1000)

  private handleTableChange(pagination): void {
    this.$set(this.pagination, 'current', pagination.current)
    this.$set(this.pagination, 'pageSize', pagination.pageSize)
    this.getData(pagination)
  }

  private reply(messageId, messageMasterId): void {
    this.$router.push({
      name: 'replyMessage',
      query: { messageId, messageMasterId, type: 'inbox' },
    })
  }

  private forward(messageId, messageMasterId): void {
    this.$router
      .push({ name: 'sendMessage', query: { messageId, messageMasterId, type: 'inbox' } })
      .catch(err => {})
  }

  private viewDetail(messageId, readFlag): void {
    if (!readFlag) {
      MessageController.setRead(messageId)
    }
    this.$router
      .push({ name: 'inDetail', params: { messageId }, query: { mesType: this.type } })
      .catch(err => {})
  }

  private sendMessage(): void {
    this.$router.push({ name: 'sendMessage' })
  }

  private refresh(): void {
    this.getData(this.pagination)
  }
}
