

























































import { createPagination } from '@/constant/constant'
import { i18n } from '@/i18n/i18n'
import { Vue, Component, Prop } from 'vue-property-decorator'
import FlexTooltip from '@/components/FlexTooltip.vue'

@Component({
  components: {
    FlexTooltip,
  },
})
export default class MissMes extends Vue {
  @Prop({}) private date!: any
  @Prop({}) private data!: any

  private pagination: any = createPagination({
    showTotal: total => i18n.t('common.pageTotal', { total }),
  })

  private get cnName(): any {
    return this.$store.state.currentStudent.name
  }

  private get enName(): any {
    return this.$store.state.currentStudent.enName || this.$store.state.currentStudent.firstName
  }

  private get lastName(): any {
    return this.$store.state.currentStudent.lastName
  }

  private get locale(): any {
    return this.$store.state.locale
  }

  private get columns(): any {
    return [
      {
        dataIndex: 'firstName',
        title: `名First Name`,
        scopedSlots: { customRender: 'commonFlex' },
      },
      {
        dataIndex: 'lastName',
        title: `姓Last Name`,
        scopedSlots: { customRender: 'commonFlex' },
      },
      {
        dataIndex: 'name',
        title: `中文名Name`,
        scopedSlots: { customRender: 'commonFlex' },
      },
      {
        dataIndex: 'sectionName',
        title: `年级Grade`,
        scopedSlots: { customRender: 'commonFlex' },
      },
      {
        dataIndex: 'className',
        title: `课程班级Class`,
        scopedSlots: { customRender: 'commonFlex' },
      },
      {
        dataIndex: 'period',
        title: `时间段Times`,
        scopedSlots: { customRender: 'commonFlex' },
      },
      {
        dataIndex: 'teacherNames',
        title: `老师Teacher`,
        scopedSlots: { customRender: 'nameFlex' },
      },
      {
        dataIndex: 'status',
        title: `考勤标记Attendance Code`,
        scopedSlots: { customRender: 'status' },
      },
    ]
  }

  private get rData(): any {
    return Object.entries(this.data)[0]?.[1] || []
  }

  private get type(): any {
    return Object.entries(this.data)[0]?.[0] || 'AM'
  }

  private handleTableChange(pagination): void {
    this.pagination.current = pagination.current
    this.pagination.pageSize = pagination.pageSize
  }
}
