


































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { MessageController } from '@/services/request.service'
import moment from 'moment'
import DiaryMes from './DiaryMes.vue'
import MissMes from './MissMes.vue'
import MissUpdate from './MissUpdate.vue'
import { toPage, getSchoolInfo } from '@/utils/utils'

const operations = {
  outbox: function(id) {
    return MessageController.getFromDetail(id)
  },
  inbox: function(id) {
    return MessageController.getToDetail(id)
  },
  outboxDel: function(id) {
    return MessageController.deleteFrom(id)
  },
  inboxDel: function(id) {
    return MessageController.deleteTo(id)
  },
}

@Component({
  components: {
    DiaryMes,
    MissMes,
    MissUpdate,
  },
})
export default class InboxMessageDetail extends Vue {
  private detail: any = {
    attachments: [],
    studentList: [],
    teacherList: [],
    parentNoticeList: [],
    toMembers: [],
  }
  private readInfo: any = {}
  private readTab = 'read'
  private history: Array<any> = []

  private moment = moment

  private get schoolId(): number {
    return (getSchoolInfo() || {}).schoolId
  }

  private get memberId(): number {
    return this.$store.state.memberId
  }

  private get isChildRoute(): boolean {
    return this.$route.name !== 'inDetail' && this.$route.name !== 'outDetail'
  }

  private get messageIds(): any {
    return this.$store.state.messageIds || []
  }

  private get type(): any {
    return this.$route.name === 'inDetail' ? 'inbox' : 'outbox'
  }

  private get messageId(): any {
    return parseInt(this.$route.params.messageId || '0', 10)
  }

  private get username(): any {
    const student = this.$store.state.currentStudent
    return `${student.fullName}${
      student.relation ? this.$t(`relation.${student.relation}`) : ''
    }`.trim()
  }

  private get mesType(): any {
    return this.$route.query.mesType || 'normal'
  }

  private get studentStatus(): any {
    return this.$store.state.currentStudent?.status
  }

  private created(): void {
    this.$route.meta.refresh = true
    this.getDetail()
  }

  private deleteMessage(): void {
    const messageId = this.type === 'inbox' ? this.messageId : this.detail.messageMasterId
    operations[this.type + 'Del'](messageId).then(res => {
      this.$route.meta.refresh = true
      this.$message.success(this.$tc('common.deleteSuccess'))
      this.$router.back()
    })
  }

  private forward(messageId, messageMasterId): void {
    this.$router
      .push({
        name: 'sendMessage',
        query: {
          messageId: this.type === 'inbox' ? messageId : messageMasterId,
          messageMasterId,
          type: this.type,
        },
      })
      .catch(err => {})
  }

  private getDetail(): void {
    const messageId = this.messageId
    operations[this.type](messageId)
      .then(res => {
        if (messageId !== this.messageId) return
        let data = res.data
        if (this.mesType === 'system') {
          const components = {
            1635: 'missUpdate',
            1636: 'missMes',
            1637: 'diaryMes',
          }
          data = data.map(item => ({
            ...item,
            content: Object.keys(components).includes(item.type)
              ? JSON.parse(item.content)
              : item.content,
            componentType: components[item.type] || 'commonMes',
            isNewSys: Object.keys(components).includes(item.type),
          }))
        }
        this.detail = {
          ...data[0],
          toMembers: (data[0]?.toTeachers || []).concat(
            data[0]?.toStudents || [],
            data[0]?.toParents || []
          ),
          fold: true,
        }
        this.history =
          res.data.length > 1
            ? res.data.slice(1, res.data.length).map(item => ({
                ...item,
                toMembers: (item.toTeachers || []).concat(
                  item.toStudents || [],
                  item.toParents || []
                ),
                fold: true,
              }))
            : []
      })
      .catch(err => {
        console.error(err)
      })
    if (this.type === 'outbox') {
      MessageController.getReadInfo(messageId)
        .then(res => {
          this.readInfo = res.data
        })
        .catch(err => {
          console.error(err)
        })
    }
  }

  private preMes(): void {
    if (this.messageIds.length <= 1) return
    const index = this.messageIds.findIndex(item => item === this.messageId)
    if (index === -1) {
      console.log('消息错误')
      return
    }
    this.$router.push({
      params: {
        messageId: this.messageIds[index - 1 === -1 ? this.messageIds.length - 1 : index - 1],
      },
      query: this.$route.query,
    })
  }

  private nextMes(): void {
    if (this.messageIds.length <= 1) return
    const index = this.messageIds.findIndex(item => item === this.messageId)
    if (index === -1) {
      console.log('消息错误')
      return
    }
    this.$router.push({
      params: {
        messageId: this.messageIds[index + 1 === this.messageIds.length ? 0 : index + 1],
      },
      query: this.$route.query,
    })
  }

  private recall(messageId): void {
    MessageController.withdraw(messageId).then(res => {
      if (res.data) {
        this.$message.success(this.$tc('message.recallSuccess'))
      } else {
        this.$message.error(this.$tc('message.recallFailed'))
      }
      this.getDetail()
    })
  }

  private reply(messageMasterId): void {
    this.$router.push({
      name: 'replyMessage',
      query: { messageId: this.messageId, messageMasterId, type: 'inbox' },
    })
  }

  @Watch('messageId', { immediate: true })
  private onIdChange(val) {
    if (!val) return
    this.getDetail()
  }
}
