





























































































































import { MessageController } from '@/services/request.service'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { getSchoolInfo } from '@/utils/utils'

import DiaryMes from './DiaryMes.vue'
import MissMes from './MissMes.vue'
import MissUpdate from './MissUpdate.vue'
const operations = {
  inbox: function(id) {
    return MessageController.getToDetail(id)
  },
  outbox: function(id) {
    return MessageController.getFromDetail(id)
  },
}

@Component({
  components: {
    DiaryMes,
    MissMes,
    MissUpdate,
  },
})
export default class MessageDetail extends Vue {
  private messageInfo: any = {}
  private history: Array<any> = []
  private loading: boolean = false
  private isIn: boolean = false

  private get schoolId(): number {
    return (getSchoolInfo() || {}).schoolId
  }

  private get type(): any {
    return this.$route.query.detailType
  }

  private get messageId(): any {
    return this.$route.params.messageId
  }

  private get username(): any {
    const student = this.$store.state.currentStudent
    return `${student.fullName}${
      student.relation ? this.$t(`relation.${student.relation}`) : ''
    }`.trim()
  }

  private get mesType(): any {
    return this.$route.query.mesType || 'normal'
  }

  private get studentStatus(): any {
    return this.$store.state.currentStudent?.status
  }

  private created(): void {
    this.getDetail()
  }

  private getDetail(): void {
    this.loading = true
    operations[this.type](this.messageId)
      .then(res => {
        let data = res.data
        if (this.mesType === 'system') {
          const components = {
            1635: 'missUpdate',
            1636: 'missMes',
            1637: 'diaryMes',
          }
          data = data.map(item => ({
            ...item,
            content: Object.keys(components).includes(item.type)
              ? JSON.parse(item.content)
              : item.content,
            componentType: components[item.type] || 'commonMes',
            isNewSys: Object.keys(components).includes(item.type),
          }))
        }
        const toMembers = (data[0]?.toTeachers || []).concat(
          data[0]?.toStudents || [],
          data[0]?.toParents || []
        )
        this.messageInfo = {
          ...data[0],
          toMembers,
          // toMemberNames: toMembers?.map(item => `${item.enName} ${item.name}${item.relationship? this.$t('relation.' + item.relationship) : ''}`.trim()).join('、'),
          toMemberNames: toMembers
            ?.map(
              item =>
                item.displayName ||
                `${item.enName} ${item.name}${
                  item.relationship ? this.$t('relation.' + item.relationship) : ''
                }`.trim()
            )
            .join('、'),
        }
        this.history =
          data.length > 1
            ? data.slice(1, data.length).map(item => {
                const toMembers = (item?.toTeachers || []).concat(
                  item?.toStudents || [],
                  item?.toParents || []
                )
                return {
                  ...item,
                  toMembers,
                  toMemberNames: toMembers
                    ?.map(
                      item =>
                        item.displayName ||
                        `${item.enName} ${item.name}${
                          item.relationship ? this.$t('relation.' + item.relationship) : ''
                        }`.trim()
                    )
                    .join('、'),
                }
              })
            : []
      })

      .catch(err => console.error(err))
      .finally(() => (this.loading = false))
  }

  private toReply(): void {
    this.$router.push({
      name: 'sendMessage',
      query: { type: 'reply', messageId: this.messageId, fromId: this.messageInfo.messageMasterId },
    })
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(to): void {
    this.isIn = this.type === 'inbox'
  }
}
