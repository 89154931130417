










































import { Vue, Component, Prop, Model } from 'vue-property-decorator'

@Component
export default class AddPerson extends Vue {
  @Model('change', { type: Array }) private value!: any
  @Prop() private readonly data!: any
  @Prop() private readonly title!: any
  @Prop() private readonly initSelected!: any
  private selected: any = []

  private get locale(): any {
    return this.$store.state.locale
  }

  private getLocaleRelation(item): string {
    return this.locale === 'zh' ? item.titleWithRelation : item.titleWithRelationEn
  }

  private toggle(index): void {
    ;(this.$refs.checkboxes as any)[index].toggle()
  }

  private mounted(): any {
    this.initSelected.forEach(item => {
      const index = this.data.findIndex(person => person.id === item.id)
      if (index === -1) return
      ;(this.$refs.checkboxes as any)[index].toggle()
    })
  }

  private confirm(): void {
    this.$emit('confirm', this.selected)
    this.back()
  }

  private back(): void {
    this.$emit('close')
  }
}
