





















































import { createPagination } from '@/constant/constant'
import { i18n } from '@/i18n/i18n'
import { Vue, Component, Prop } from 'vue-property-decorator'
import FlexTooltip from '@/components/FlexTooltip.vue'

@Component({
  components: {
    FlexTooltip,
  },
})
export default class MissUpdate extends Vue {
  @Prop({}) private type!: any
  @Prop({}) private date!: any
  @Prop({}) private data!: any
  private pagination: any = createPagination({
    showTotal: total => i18n.t('common.pageTotal', { total }),
  })

  private get cnName(): any {
    return this.$store.state.currentStudent.name
  }

  private get enName(): any {
    return this.$store.state.currentStudent.enName || this.$store.state.currentStudent.firstName
  }

  private get lastName(): any {
    return this.$store.state.currentStudent.lastName
  }

  private get rData(): any {
    return this.data.list || []
  }

  private get columns(): any {
    return [
      {
        dataIndex: 'firstName',
        title: `名First Name`,
        ellipsis: true,
        customHeaderCell: (record, index) => {
          return {
            attrs: {
              title: `名First Name`,
            },
          }
        },
        scopedSlots: { customRender: 'commonFlex' },
      },
      {
        dataIndex: 'lastName',
        title: `姓Last Name`,
        ellipsis: true,
        customHeaderCell: (record, index) => {
          return {
            attrs: {
              title: `姓Last Name`,
            },
          }
        },
        scopedSlots: { customRender: 'commonFlex' },
      },
      {
        dataIndex: 'name',
        title: `中文名Name`,
        ellipsis: true,
        customHeaderCell: (record, index) => {
          return {
            attrs: {
              title: `中文名Name`,
            },
          }
        },
        scopedSlots: { customRender: 'commonFlex' },
      },
      {
        dataIndex: 'sectionName',
        title: `年级Grade`,
        ellipsis: true,
        customHeaderCell: (record, index) => {
          return {
            attrs: {
              title: `年级Grade`,
            },
          }
        },
        scopedSlots: { customRender: 'commonFlex' },
      },
      {
        dataIndex: 'className',
        title: `课程班级Class`,
        ellipsis: true,
        customHeaderCell: (record, index) => {
          return {
            attrs: {
              title: `课程班级Class`,
            },
          }
        },
        scopedSlots: { customRender: 'commonFlex' },
      },
      {
        dataIndex: 'attendanceTime',
        title: `日期Date`,
        ellipsis: true,
        customHeaderCell: (record, index) => {
          return {
            attrs: {
              title: `日期Date`,
            },
          }
        },
        scopedSlots: { customRender: 'time' },
      },
      {
        dataIndex: 'period',
        title: `时间段Times`,
        ellipsis: true,
        customHeaderCell: (record, index) => {
          return {
            attrs: {
              title: `时间段Times`,
            },
          }
        },
        scopedSlots: { customRender: 'commonFlex' },
      },
      {
        dataIndex: 'teacherNames',
        title: `老师Teacher`,
        ellipsis: true,
        customHeaderCell: (record, index) => {
          return {
            attrs: {
              title: `老师Teacher`,
            },
          }
        },
        scopedSlots: { customRender: 'nameFlex' },
      },
      {
        dataIndex: 'status',
        title: `新考勤标记 New Attendance Code`,
        ellipsis: true,
        customHeaderCell: (record, index) => {
          return {
            attrs: {
              title: `新考勤标记 New Attendance Code`,
            },
          }
        },
        scopedSlots: { customRender: 'status' },
      },
    ]
  }

  private handleTableChange(pagination): void {
    this.pagination.current = pagination.current
    this.pagination.pageSize = pagination.pageSize
  }
}
